import { UiTagVanilla } from '@skillbox/front-box/dist/VisualElements/UiTag/UiTagVanilla'
import UiModal from '../UiModal/UiModal'
import Form from '../../Form/Form'
import { ClassName } from '../../Form/const'
import { defaultRules } from '../../Form/default-rules'
import { Timer } from '../Timer/Timer'
import { getVkAuthModel } from '../VKConnect'
import { translate } from '../../utils/translations'
import { render, createElement, RenderPosition } from '../../utils/render'
import { Media } from '../../utils/const';
import { saleEndDate } from '../SaleEndDate/SaleEndDate'
import { showAutopaymentModal } from '../../../components/popups/v1/AutopayModal/scripts'
import { showAlchemerePopup } from '../Alchemere'

const SubmitType = {
  CONSULT: 'consult',
  PAY: 'pay'
}

const ButtonText = {
  consult: translate('form.button.consultation'),
  pay: translate('form.button.pay')
}

const getSubmitTypesTemplate = () => {
  return (
    `<div class="cart-types">
        <div class="cart-types__method ui-autopayment-method ui-autopayment-method--radio">
          <label class="ui-autopayment-method__label">
            <input
              name="submit_type"
              value="${SubmitType.CONSULT}"
              type="radio"
              checked
              class="ui-autopayment-method__input sr-only"
            >

            <span class="ui-autopayment-method__box">
              <span class="ui-autopayment-method__title f f--16 f--m">
                Записаться на&nbsp;бесплатную консультацию
              </span>
            </span>
          </label>
        </div>

        <div class="cart-types__method ui-autopayment-method ui-autopayment-method--radio">
          <label class="ui-autopayment-method__label">
            <input
              name="submit_type"
              value="${SubmitType.PAY}"
              type="radio"
              class="ui-autopayment-method__input sr-only"
            >

            <span class="ui-autopayment-method__box ui-autopayment-method__box--tag">
              <span class="ui-autopayment-method__title f f--16 f--m">
                Оплатить и&nbsp;получить дополнительную скидку
              </span>

              ${UiTagVanilla({
                data: {
                  title: 'Выгодно',
                  background_color: 'var(--accent-sale-color)',
                  color: 'var(--accent-always-black-color)'
                },
                classes: 'ui-autopayment-method__tag'
              })}
            </span>
          </label>
        </div>
      </div>`
  )
}

export class CartModal extends UiModal {
  constructor(data) {
    const autoPaymentFormTemplate = document.querySelector('#cart-modal')
      .content
      .cloneNode(true)
      .querySelector('.cart-modal__wrapper')

    super(autoPaymentFormTemplate, {
      wrapperClass: 'cart-modal',
      outerScroll: true,
      isIndent: false
    })

    this.buttonWrapperElelent = null

    this.formElement = null
    this.formInstance = null

    this.timer = null
    this.data = data.tariffData
    this.blockTriggerName = data.blockName
    this.smMax = window.matchMedia(`(max-width: ${Media.SM_MAX}px)`)
    this.currentType = SubmitType.CONSULT
  }

  async beforeMounted() {
    this.buttonWrapperElelent = this.element.querySelector('.cart-modal__button-wrapper')
    this.formElement = this.element.querySelector('.form')

    this.fillCardBlock(this.data)
    this.renderSubmitTypes()

    this.formElement.type.value = this.data.type
    this.formElement['tariff_title'].value = this.data.title
    this.formElement['nomenclature_id'].value = this.data.nomenclature_id

    const vkConnectContainer = this.element.querySelector('[data-auth="vk-connect"]')
    const vkAuthModel = await getVkAuthModel([vkConnectContainer])
    this.formInstance = new Form({ form: this.formElement, rules: defaultRules, vkAuthModel, autopaymentData: this.data.autopayment, blockName: this.blockTriggerName })
    this.formInstance.init()

    if (this.smMax.matches) {
      this.setPaddingForButton(this.buttonWrapperElelent.offsetHeight)
      this.setShadowForButton(this.scrollingEl)
      this.scrollingEl.addEventListener('scroll', this.modalScrollHandler)
      this.buttonWrapperElelent.querySelector('.cart-modal__button')
        .addEventListener('click', this.buttonClickHandler)
    }

    this.smMax.addEventListener('change', this.smMediaChangeHandler)
    this.formElement.addEventListener('change', this.formChangeHandler)
    this.formElement.addEventListener('form-success', this.formSubmitHandler)
  }

  beforeUnmounted() {
    if (this.smMax.matches) {
      this.buttonWrapperElelent.querySelector('.cart-modal__button')
        .removeEventListener('click', this.buttonClickHandler)
      this.scrollingEl.removeEventListener('scroll', this.modalScrollHandler)
    }

    this.smMax.removeEventListener('change', this.smMediaChangeHandler)
    this.formElement.removeEventListener('change', this.formChangeHandler)
    this.formElement.removeEventListener('form-success', this.formSubmitHandler)
  }

  unmounted() {
    this.timer?.destroy()
  }

  smMediaChangeHandler = (evt) => {
    if (evt.matches) {
      this.setPaddingForButton(this.buttonWrapperElelent.offsetHeight)
      this.scrollingEl.addEventListener('scroll', this.modalScrollHandler)
      this.buttonWrapperElelent.querySelector('.cart-modal__button')
        .addEventListener('click', this.buttonClickHandler)
    } else {
      this.setPaddingForButton()
      this.scrollingEl.removeEventListener('scroll', this.modalScrollHandler)
      this.buttonWrapperElelent.querySelector('.cart-modal__button')
        .removeEventListener('click', this.buttonClickHandler)
    }

    this.setShadowForButton(this.scrollingEl)
  }

  pushAutopaymentAnalytics(type) {
    window.$analytics?.push('price-type-click', {
      action: `Select ${type}`,
      blockName: this.blockTriggerName
    });
  }

  renderSubmitTypes() {
    if (!this.data.payments?.length) {
      return
    }

    const submitTypesElement = createElement(getSubmitTypesTemplate())

    submitTypesElement.addEventListener('change', (evt) => {
      this.currentType = evt.target.value;

      this.setButtonText(this.currentType)
      this.pushAutopaymentAnalytics(this.currentType);
    })

    render(this.formElement, submitTypesElement, RenderPosition.AFTERBEGIN)
  }

  setButtonText(type) {
    this.element.querySelector('.cart-modal__button .ui-button__text')
      .textContent = ButtonText[type]
        ? ButtonText[type]
        : ButtonText.consult
  }

  formSubmitHandler = (evt) => {
    const { formData } = evt.sendingResult;
    const requestId = formData.get('request_id');

    window.$analytics?.push('cart-modal-form-send', {
      label: `${this.data.name} | ${this.currentType}`,
      requestId,
      blockName: this.blockTriggerName
    })

    if (!this.data.payments?.length) {
      showAlchemerePopup('order') !== null && this.close()
      return
    }

    const template = document.querySelector('#auto-payment-form')
      ?.content.cloneNode(true).querySelector('.auto-payment-form')

    if (template) {
      showAutopaymentModal(template, {
        nomenclatureId: this.data.nomenclature_id,
        methods: this.data.payments,
        type: this.currentType,
        blockName: this.blockTriggerName
      })
      this.close()
    }
  }

  setPaddingForButton(offset) {
    this.element.querySelector('.cart-modal__form')
      .style.paddingBottom = offset ? `${offset - 40}px` : 0
  }

  setShadowForButton(target) {
    this.smMax.matches && (target.scrollHeight - target.offsetHeight) !== target.scrollTop && !this.element.querySelector(`.${ClassName.SUCCESS}`)
      ? this.buttonWrapperElelent.classList.add('ui-shadow-8pt')
      : this.buttonWrapperElelent.classList.remove('ui-shadow-8pt')
  }

  modalScrollHandler = (evt) => {
    this.setShadowForButton(evt.target)
  }

  buttonClickHandler = () => {
    if (!this.formInstance.isValid) {
      this.scrollingEl.scrollTo({
        top: this.scrollingEl.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  fillCardBlock(data) {
    const card = this.element.querySelector('.cart-price-block')

    const descriptions = data.descriptions.map((item) => {
      return `<li class="cart-price-block__condition">${item}</li>`
    }).join('')

    const cardStr = card.outerHTML
      .replace(/#title#/g, data.title)
      .replace(/#sale#/g, data.sale)
      .replace(/#priceNew#/g, data.price.new)
      .replace(/#priceNewCurrency#/g, data.price.newCurrency)
      .replace(/#priceOld#/g, data.price.old)
      .replace(/#priceOldCurrency#/g, data.price.oldCurrency)
      .replace(/#bullets#/g, descriptions)

    const newCard = createElement(cardStr)
    const timerWrapper = newCard.querySelector('.cart-price-block__timer')

    if (data.sale) {
      data.isTimer
        ? this.startTimer(timerWrapper)
        : timerWrapper.remove()
    } else {
      timerWrapper.remove()
      newCard.querySelector('.cart-price-block__sale').remove()
    }

    card.parentElement.replaceChild(newCard, card)
  }

  startTimer(timerWrapper) {
    saleEndDate.addObserver(time => {
      this.timer = new Timer({ time, timerWrapper })
    })
  }
}
