import UiModal from '../../../../js/components/UiModal/UiModal'
import { showAlchemerePopup } from '../../../../js/components/Alchemere'
import { showAutopaymentModal } from '../../../popups/v1/AutopayModal/scripts'
import { Slider } from '../../../../js/components/Slider/Slider'
import { Media } from '../../../../js/utils/const'

const MatchMedia = {
  sm: window.matchMedia(`(max-width: ${Media.SM_MAX}px)`),
  lg: window.matchMedia(`(min-width: ${Media.LG}px)`)
}
const indexToHeight = new Map()

class TariffsModal extends UiModal {
  constructor(template, options) {
    super(template, {
      blockTriggerName: options.blockName || 'Блок без названия',
      isIndent: false,
      wrapperClass: 'tariffs-v4-modal'
    })

    this.type = options.type || 'pay'
    this.wrapper = null
    this.headers = null
    this.features = null
  }

  mounted() {
    this.wrapper = this.element.querySelector('.tariffs-v4-modal__wrapper')
    this.items = this.element.querySelectorAll('.tariffs-v4__item')
    this.headers = this.element.querySelectorAll('.tariffs-v4__top')
    this.features = this.element.querySelectorAll('.tariffs-v4__features')

    this.sliderInit(this.wrapper, this.items)
    this.tariffsResize(this.wrapper, this.headers, this.features)
    this.wrapper.addEventListener('click', this.tariffsClickHandler)
  }

  beforeClose() {
    showAlchemerePopup('lead')
  }

  sliderInit = (wrapper, items) => {
    const options = {
      wrapper,
      settings: {
        loop: false,
        autoScrollOffset: 1,
        centeredSlides: true,
        centerInsufficientSlides: true,
        centeredSlidesBounds: true,
        slideClass: 'tariffs-v4__item',
        wrapperClass: 'tariffs-v4__list',
        spaceBetween: 8,
      },
      initCondition: {
        sm: items.length > 1,
        md: items.length > 1,
        lg: false,
        xl: false
      },
      createCallback: (slider) => {
        slider?.slideTo?.(1, 0)
        wrapper.classList.remove('tariffs-v4__content--static')
      },
      destroyCallback: () => {
        wrapper.classList.add('tariffs-v4__content--static')
      }
    }

    const slider = new Slider(options)
    slider.init()
    slider?.slider?.slideTo(1, 0)
  }

  tariffsResize = (wrapper, headers, features) => {
    const loopAtFeatures = (lists, callback) => {
      lists.forEach((list) => {
        const items = list.querySelectorAll('.tariffs-v4__feature')
        items.forEach(callback)
      })
    }

    const fillHeightMap = (item, i) => {
      item.removeAttribute('style')
      const height = item.clientHeight

      indexToHeight.set(i, indexToHeight.has(i)
        ? Math.max(indexToHeight.get(i), height)
        : height
      )
    }

    const setItemHeight = (item, i) => {
      item.style.height = indexToHeight.has(i) ? `${indexToHeight.get(i)}px` : 'auto'
    }

    const setHeight = (elements) => {
      const heights = [...elements].map((p) => {
        p.removeAttribute('style')
        return p.clientHeight
      })
      const maxHeight = Math.max(...heights)

      elements.forEach((el) => {
        el.style.height = `${maxHeight}px`
      })
    }
    const updateItemHeight = () => {
      indexToHeight.clear()
      setHeight(headers)
      loopAtFeatures(features, fillHeightMap)
      loopAtFeatures(features, setItemHeight)
    }

    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        for (const entry of entries) {
          if (entry.target === wrapper) {
            updateItemHeight()
          }
        }
      })
    })

    resizeObserver.unobserve(wrapper)
    resizeObserver.observe(wrapper)

    updateItemHeight()

    MatchMedia.sm.removeEventListener('change', updateItemHeight)
    MatchMedia.lg.removeEventListener('change', updateItemHeight)
    MatchMedia.sm.addEventListener('change', updateItemHeight)
    MatchMedia.lg.addEventListener('change', updateItemHeight)
  }

  tariffsClickHandler = (evt) => {
    const button = evt.target.closest('.tariffs-v4__button')

    if (!button) {
      return
    }

    const card = button.closest('.tariffs-v4__item')
    const data = JSON.parse(card.dataset.tariffData)

    if (!data.payments?.length) {
      showAlchemerePopup('order') !== null && this.close()
      return
    }

    /* eslint-disable-next-line */
    !isGbHost && window.$analytics?.push('fl-show-form')

    window.$analytics?.push('tariff-subscribe-button-click', {
      tariffName: data.name,
      blockName: this.blockTriggerName
    });

    const template = document.querySelector('#auto-payment-form')
      ?.content.cloneNode(true).querySelector('.auto-payment-form')

    if (!template) {
      throw new Error('[Tariffs modal]: Template of auto-payment-form is not found')
    }

    showAutopaymentModal(template, {
      nomenclatureId: data.nomenclature_id,
      methods: data.payments,
      type: this.type,
      blockName: this.blockTriggerName
    })

    this.close()
  }
}

export const showTariffsModal = (template, options) => {
  if (!template) {
    return
  }

  new TariffsModal(template, options).init()
}
